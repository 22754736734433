
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';

import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import HotelCatalogSearch from '@/modules/hotels/modules/hotel-catalog/components/hotel-catalog-search.vue';
import HotelCatalogService, { HotelCatalogServiceS } from '@/modules/hotels/modules/hotel-catalog/hotel-catalog.service';
import Map from '@/modules/common/components/map/googlemap.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import type HotelModel from '@/modules/hotels/models/hotel.model';
import type CompsetsService from '@/modules/compsets/compsets.service';

// @ts-ignore
import mapMyHotelIcon from '@/modules/common/assets/map_my_hotel.png';

@Component({
    components: {
        HotelCatalogSearch,
        CustomCheckbox,
        Map,
    },
})
export default class AddHotelsTab extends Vue {
    static title = 'Add Hotel';

    @inject(HotelCatalogServiceS)
    hotelCatalogService!: HotelCatalogService;

    @inject(KEY.CompsetsService)
    compsetsService!: CompsetsService;

    // NOTE: This is the validation state of this tab
    //       that works via v-model
    @Prop({
        type: Boolean,
        default: false,
    })
    value!: boolean;

    centerOfTheMap = { lat: 0, lng: 0 };
    errors = [];
    query = '';
    isValid = false;
    unsubscribeFromCatalog!: () => void;

    get hotelMarkers() {
        const toMarker = (hotel: HotelModel) => ({
            icon: mapMyHotelIcon,
            position: hotel.geoLocation,
        });
        return this.foundHotels.map(toMarker);
    }

    get foundHotels() {
        const { customerHotelFornovaId } = this.onboardingData;
        const FOUND_HOTELS = this.hotelCatalogService.data;

        const DEFAULT_ARRAY = customerHotelFornovaId !== -1
            ? [this.choosedHotel!]
            : [];
        return FOUND_HOTELS && FOUND_HOTELS.length ? FOUND_HOTELS : DEFAULT_ARRAY;
    }

    get onboardingData() {
        return this.compsetsService.onboarding.data;
    }

    get isCatalogLoading() {
        return this.hotelCatalogService.isLoading;
    }

    get isNotFound() {
        return !this.isEmptySearchBar
            && !this.foundHotels.length
            && !this.isCatalogLoading;
    }

    get choosedHotel() {
        return this.compsetsService.onboarding.choosedHotel;
    }

    set choosedHotel(value: HotelModel | null) {
        this.compsetsService.onboarding.choosedHotel = value;
    }

    get isEmptySearchBar() {
        return !this.query;
    }

    beforeMount() {
        this.unsubscribeFromCatalog = this.hotelCatalogService
            .onCatalogChanged(this.updateMapCenter.bind(this));
    }

    beforeDestroy() {
        this.unsubscribeFromCatalog();
    }

    updated() {
        this.updateValidState();
    }

    updateValidState() {
        const { isValid: oldValidState } = this;

        this.isValid = this.onboardingData.customerHotelFornovaId !== -1;

        if (this.isValid !== oldValidState) {
            this.emitValidState(this.isValid);
        }
    }

    onSearchTriggered(newQuery: string) {
        this.query = newQuery;
    }

    setHotel(item: HotelModel) {
        this.onboardingData.customerHotelFornovaId = item.id;
        this.choosedHotel = item;
        this.centerOfTheMap = item.geoLocation || this.centerOfTheMap;
    }

    emitValidState(isValid: boolean) {
        this.$emit('input', isValid);
    }

    updateMapCenter([firstFound]: HotelModel[]) {
        if (!firstFound) return;

        if (!this.choosedHotel) {
            this.centerOfTheMap = firstFound.geoLocation || this.centerOfTheMap;
        }
    }

    isHotelChoosed(item: HotelModel) {
        return item.id === this.onboardingData.customerHotelFornovaId;
    }
}
