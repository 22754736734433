
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';

import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type CompsetsService from '@/modules/compsets/compsets.service';
import HotelCatalogSearch from '@/modules/hotels/modules/hotel-catalog/components/hotel-catalog-search.vue';
import HotelCatalogService, { HotelCatalogServiceS } from '@/modules/hotels/modules/hotel-catalog/hotel-catalog.service';
import Map from '@/modules/common/components/map/googlemap.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import type HotelModel from '@/modules/hotels/models/hotel.model';

// @ts-ignore
import mapMyHotelIcon from '@/modules/common/assets/map_my_hotel.png';

@Component({
    components: {
        HotelCatalogSearch,
        CustomCheckbox,
        Map,
    },
})
export default class AddCompetitorsTab extends Vue {
    static title = 'Add Competitors';

    @inject(HotelCatalogServiceS)
    hotelCatalogService!: HotelCatalogService;

    @inject(KEY.CompsetsService)
    compsetsService!: CompsetsService;

    // NOTE: This is the validation state of this tab
    //       that works via v-model
    @Prop({
        type: Boolean,
        default: false,
    })
    value!: boolean;

    centerOfTheMap = { lat: 0, lng: 0 };
    errors = [];
    query = '';
    isValid = false;
    unsubscribeFromCatalog!: () => void;

    get hotelMarkers() {
        const toMarker = (hotel: HotelModel) => ({
            icon: mapMyHotelIcon,
            position: hotel.geoLocation,
        });
        return this.foundHotels.map(toMarker);
    }

    get foundHotels() {
        const { customerHotelFornovaId } = this.onboardingData;
        const FOUND_HOTELS = this.hotelCatalogService.data || [];
        const withoutChoosedCustomerHotel = (item: HotelModel) => item.id !== customerHotelFornovaId;
        const withoutFoundHotels = (item: HotelModel) => !FOUND_HOTELS
            .some(foundItem => foundItem.id === item.id);

        const DEFAULT_LIST = this.competitors.filter(withoutFoundHotels);
        const list = Array.from(
            new Set([
                ...DEFAULT_LIST,
                ...FOUND_HOTELS,
            ]),
        );

        return list.filter(withoutChoosedCustomerHotel);
    }

    get onboardingData() {
        return this.compsetsService.onboarding.data;
    }

    get isCatalogLoading() {
        return this.hotelCatalogService.isLoading;
    }

    get isNotFound() {
        return !this.isEmptySearchBar
            && !this.foundHotels.length
            && !this.isCatalogLoading;
    }

    get canAddCompetitors() {
        return this.compsetsService.onboarding.data.competitors.length < 5;
    }

    get isEmptySearchBar() {
        return !this.query;
    }

    get competitorsIds() {
        const { choosedCompetitors } = this.compsetsService.onboarding;

        return choosedCompetitors.map(comp => comp.id);
    }

    get competitors() {
        return this.compsetsService.onboarding.choosedCompetitors || [];
    }

    set competitors(value: HotelModel[]) {
        this.compsetsService.onboarding.choosedCompetitors = value;
    }

    beforeMount() {
        this.clearResults();
        this.unsubscribeFromCatalog = this.hotelCatalogService
            .onCatalogChanged(this.updateMapCenter.bind(this));
    }

    beforeDestroy() {
        this.unsubscribeFromCatalog();
    }

    updated() {
        this.updateValidState();
    }

    updateValidState() {
        const { isValid: oldValidState } = this;

        const { competitors } = this.compsetsService.onboarding.data;
        this.isValid = !!competitors.length && competitors.length < 6;

        if (this.isValid !== oldValidState) {
            this.emitValidState(this.isValid);
        }
    }

    emitValidState(isValid: boolean) {
        this.$emit('input', isValid);
    }

    clearResults() {
        this.hotelCatalogService.clearResults();
    }

    onSearchTriggered(newQuery: string) {
        this.query = newQuery;
    }

    isCompetitorBlocked(item: HotelModel) {
        return !this.canAddCompetitors && !this.isCompetitorChoosed(item);
    }

    toggleCompetitor(item: HotelModel) {
        if (this.isCompetitorBlocked(item)) return;

        this.centerOfTheMap = item.geoLocation || this.centerOfTheMap;

        if (this.isCompetitorChoosed(item)) {
            this.competitors = this.competitors
                .filter(comp => comp.id !== item.id);
        } else {
            this.competitors.push(item);
        }

        this.updateCompetitorIds();
    }

    isCompetitorChoosed(competitor: HotelModel) {
        return this.onboardingData.competitors
            .some(competitorId => competitorId === competitor.id);
    }

    updateCompetitorIds() {
        this.onboardingData.competitors = this.competitors.map(comp => comp.id);
    }

    updateMapCenter([firstFound]: HotelModel[]) {
        if (!firstFound) return;
        if (this.competitors.length) return;

        this.centerOfTheMap = firstFound.geoLocation || this.centerOfTheMap;
    }
}
