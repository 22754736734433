import { container } from '@/inversify.container';
import { KEY } from '@/inversify.keys';
import type ConfigService from '@/modules/config/config.service';
// from docs https://developers.google.com/maps/documentation/javascript/using-typescript#module_import
// eslint-disable-next-line import/no-unresolved
import {} from 'googlemaps';

declare global {
    interface Window {
        gmapsCallback: Function;
        google: Function;
    }
}

const API_KEY = String(container.get<ConfigService>(KEY.ConfigService).googleKey);

let initialized = !!window.google;
let resolveInitPromise: { (arg0: Function & typeof google): any; (value?: unknown): void; };
let rejectInitPromise: OnErrorEventHandler;

const initPromise = new Promise((resolve, reject) => {
    resolveInitPromise = resolve;
    rejectInitPromise = reject;
});

export default function init() {
    if (initialized) return initPromise;

    initialized = true;

    window.gmapsCallback = () => resolveInitPromise(window.google);

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=gmapsCallback&libraries=geometry`;
    script.onerror = rejectInitPromise;
    document.head.appendChild(script);

    return initPromise;
}
